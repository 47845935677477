.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #05b698 !important;
}

.ant-menu.ant-menu-dark li:hover {
  background-color: #05b698 !important;
}

.ant-btn-primary{
  background-color: #05b698 !important;
  border: 1px solid #05b698 !important;
}

.ant-btn-primary:hover{
  background-color: #06b194 !important;
  border: 1px solid #06b194 !important;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin-bottom: 110px;
}

.logo img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.logo h1{
  font-size: 35px;
  text-align: center;
  color: #fff;
}

.selected {
  font-weight: bold;
  color: red;
}

.site-layout .site-layout-background {
  background: #fff;
}

.site-layout .site-layout-background .account-dropdown{
  margin-top: 16px;
  margin-right: 18px;
  float: right;
}

.site-page-header-ghost-wrapper {
  padding: 24px;
  border: 1px solid rgb(235, 237, 240);
  border-radius: 5px;
}
.btn_link span {
  padding: 3px;
}

.btn_link a {
  color: #fff;
}

.days-checkbox{
  margin-bottom: 25px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.image-upload{
  display: inline-flex;
  margin-left: 42px;
  
}

.image-upload .label-div{
  width: 105px;
  margin-right: 10px;
  height: 40px;
}

.image-upload .label-div label{
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  float: right;
  margin-top: 5px;
}

.image-upload .input-file input{
    margin-bottom: 20px;
    display: block;
    max-width: 97%;
    padding: .250rem .75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.85);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d9d9d9;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.logo .avatar-img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ant-select-selection-selected-value {
  font-weight: 700 !important;
}

.sizes-area .ant-form-item {
  margin-bottom: 8px !important;
}