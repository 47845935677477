
.login-form-button{
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  background-color: #05b698 !important;
  border: 1px solid #05b698 !important;
  
}

.login-box{
  margin-left: auto;
  margin-right: auto;
  margin-top: 12%;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.08);
  width: 380px;
  border-radius: 10px !important;
}

.login-box .ant-card-head-title{
  text-align: center;
  font-size: 20px;
}

.login-box .ant-card{
  border-radius: 10px;
}

.register{
  text-align: center;
}

.register h4{
  margin-top: 10px;
}

.register h3:hover{
  color: blue;
}

@media screen and (max-width: 419px) {
  .login-box{
    width: 340px;
  }
}

.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.bg-half-260, .bg-home {
  background-size: cover;
  -webkit-align-self: center;
  align-self: center;
  position: relative;
  background-position: 50%;
  height: 100vh;
}

.align-items-center {
  -webkit-align-items: center!important;
  align-items: center!important;
}

.d-flex {
  display: -webkit-flex!important;
  display: flex!important;
}

.bg-overlay {
  background-image: url('https://rr-healthcare.com/static/media/gloves1.6b54adab.jpg'); /* /static/media/GlobeInHand.2ab11206.jpg */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}