/* blog previews / list */
.blog-preview {
    padding: 10px 16px;
    margin: 20px 0;
    border-bottom: 1px solid #fafafa;
  }
  .blog-preview:hover {
    box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
  }
  .blog-preview h2 {
    font-size: 20px;
    color: #26a7b1;
    margin-bottom: 8px;
  }
  .blog-preview a{
      text-decoration: none;
      color: dimgray;
  }
  /* blog details page */
.blog-details h2 {
    font-size: 20px;
    color: #26a7b1;
    margin-bottom: 10px;
    }
    .blog-details div {
    margin: 20px 0;
    }

/* create new blog form */
.create {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
  }
  .create label {
    text-align: left;
    display: block;
  }
  .create h2 {
    font-size: 20px;
    color: #26a7b1;
    margin-bottom: 30px;
  }
  .create input, .create textarea, .create select {
    width: 100%;
    padding: 6px 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    box-sizing: border-box;
    display: block;
  }
  .create button {
    background: #26a7b1;
    color: #fff;
    border: 0;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
  }    